<template>
  <div class="sign-up">
    <header class="page-header">{{ $t('Login.欢迎注册') }} {{ title }}</header>
    <main class="page-body">
      <van-tabs v-model="mode" class="form-field">
        <van-tab :title="$t('Login.手机注册')"></van-tab>
        <van-tab :title="$t('Login.邮箱注册')"></van-tab>
      </van-tabs>
      <div class="label">{{ $t('Login.账号') }}</div>
      <van-field
        v-model="username"
        clearable
        :placeholder="$t('Login.请输入手机号')"
        class="form-field"
      ></van-field>
      <div class="form-field flexbox align-center" v-show="mode === 1">
        <van-field
          v-model="captcha"
          clearable
          :placeholder="$t('Login.请输入图形验证码')"
        ></van-field>
        <img
          id="img-checkcode1"
          :src="imgCodeUrl"
          v-if="rerender"
          @click="onImgClick"
          width="95"
          height="35"
        />
      </div>
      <!-- <div
        class="slider form-field"
        id="__Verification"
        ref="slider"
        v-show="mode === 1"
      ></div> -->
      <Vcode :show="isShow" @success="success" />

      <div class="flexbox align-center form-field" v-show="mode === 0">
        <van-field
          v-model="code"
          type="digit"
          clearable
          :maxlength="6"
          :placeholder="$t('Login.请输入验证码')"
        >
        </van-field>
        <van-button
          type="info"
          class="code-btn"
          :disabled="!username || counting"
          @click="onSend"
        >
          <van-count-down
            :time="60000"
            v-if="counting"
            @finish="counting = false"
          >
            <template #default="timeData">
              <span class="block"
                >{{ $t('Login.重新获取') }} ({{ timeData.seconds }})</span
              >
            </template>
          </van-count-down>
          <span v-else>{{ $t('Login.获取验证码') }}</span>
        </van-button>
      </div>
      <div class="label">{{ $t('Login.密码') }}</div>
      <van-field
        v-model="password"
        :type="showPassword ? 'input' : 'password'"
        :placeholder="$t('Login.请输入6位数密码')"
        class="form-field"
      >
        <template #right-icon>
          <van-icon
            :name="showPassword ? 'closed-eye' : 'eye-o'"
            @click="showPassword = !showPassword"
          />
        </template>
      </van-field>
      <van-button
        class="submit"
        type="info"
        @click="humancheck"
        :disabled="!valid"
      >
        {{ $t('Login.注册') }}
      </van-button>
      <div class="login x-center">
        <span>{{ $t('Login.已有账户') }}？</span>
        <router-link class="theme-color" :to="{ name: 'Login' }">
          {{ $t('Login.立即登录') }}
        </router-link>
      </div>
    </main>
  </div>
</template>
<script>
import { sendSMSCode, signUp, signUpWithEmail } from '@/api/account.js';
//import $ from 'jquery';
import { BASE_URL } from '@/api/index.js';
import { APP_TITLE } from '@/api/index.js';
import Vcode from 'vue-puzzle-vcode';
export default {
  data() {
    return {
      mode: 0,
      username: '',
      password: '',
      code: '',
      captcha: '',
      showPassword: false,
      counting: false,
      rerender: true,
      imgCodeUrl: BASE_URL + '/customer/GetVerifyImg',
      title: APP_TITLE,
      isShow: false, //是否显示图片滑动验证框
    };
  },
  // watch: {
  //   mode(newVal) {
  //     if (newVal == 1) {
  //       this.isShow = true;
  //     } else {
  //       this.isShow = false;
  //     }
  //   },
  // },
  components: {
    Vcode,
  },
  computed: {
    valid() {
      if (this.mode === 0) {
        return this.username && this.password && this.code;
      }
      return this.username && this.password && this.captcha;
    },
  },
  mounted() {
    /******************************************************
     * 参数一 验证码图片规格 "300*300", "300*200", "200*100"
     * 参数二 校验通过时执行的回调函数
     * 绑定的div width与图片宽一致 height为图片高加34像素
     ******************************************************/
    // console.log($(this.$refs.slider).slide);
    // $(this.$refs.slider).slide({
    //   imgspec: '300*200',
    //   successCallBack: function() {
    //     console.log(
    //       '%c\u606d\u559c\u4f60\u002c\u6821\u9a8c\u901a\u8fc7\u0021',
    //       'background:rgba(255,249,0,.1);color:#00ff21;font-size:40px;'
    //     );
    //   },
    // });
  },
  methods: {
    humancheck() {
      this.isShow = true;
    },
    success() {
      this.isShow = false;
      this.onSubmit();
    },
    onImgClick() {
      this.rerender = false;
      this.$nextTick(() => {
        this.rerender = true;
      });
    },
    onSend() {
      this.counting = true;
      sendSMSCode({ tel: this.username }).then(() => {
        this.$toast.success('发送成功');
      });
    },
    onSubmit() {
      this.$toast.loading({ message: '' });
      const p =
        this.mode === 0
          ? signUp({
              username: this.username,
              password: this.password,
              code: this.code,
            })
          : signUpWithEmail({
              email: this.username,
              password: this.password,
              captcha: this.captcha,
            });
      p.then(res => {
        if (res.Result) {
          this.$store.commit('SET_USER_INFO', res.DataDict.user); //设置全局变量
          this.$toast.success(res.Info);
          setTimeout(() => {
            this.$router.replace({ name: 'Home' });
          }, 1000);
        } else {
          this.$toast.fail(res.Info);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
@import '~@/styles/drag.css';
.sign-up {
  background-color: #fff;
}
.page-header {
  font-size: 21px;
  justify-content: center;
  font-weight: bold;
}
.page-body {
  padding: rem(60px);
}
.label {
  font-size: 16px;
  margin-bottom: rem(30px);
}
.form-field {
  margin-bottom: rem(40px);
}
.van-field {
  border-radius: rem(25px);
  background-color: #f7f7fc;
  font-size: 16px;
  padding-top: rem(24px);
  padding-bottom: rem(24px);
}
.submit {
  height: rem(100px);
  line-height: rem(100px);
  width: 100%;
  border-radius: rem(50px);
  margin-top: rem(156px);
  font-size: 16px;
}
.login {
  color: #b2b2c4;
  margin-top: rem(212px);
}
.van-count-down {
  color: #fff;
}
.code-btn {
  margin-left: rem(20px);
  white-space: nowrap;
  border-radius: rem(25px);
}
</style>
